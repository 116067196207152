import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    ArrayInput,
    Create,
    ImageField,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import serviceability from '../../data/serviceability.json';
import loadingTypes from '../../data/loadingType.json';
import options from './serviceMode.mock';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ServiceModeCreate = (props) => {
    const classes = useStyles();
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.service-mode.name'),
            url: 'service-mode',
            icon: <ErrorIcon />,
        },
        {
            text: translation('resources.service-mode.create.name'),
            url: '',
        },
    ];
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands).filter((x) => x.id !== 'all');
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceTypes}
                            validate={required()}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="brand"
                            choices={[
                                ...brands,
                                {
                                    id: 'TERZISMO',
                                    name: 'TERZISMO',
                                },
                            ]}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="series"
                            choices={series.sort((a, b) =>
                                a.name.localeCompare(b.name)
                            )}
                        />
                        <CustomSelectInput
                            source="seriesVersion"
                            choices={seriesVersion}
                        />
                        <CustomSelectInput
                            source="loadingType"
                            allowEmpty
                            choices={loadingTypes}
                        />
                        <TextInput source="installationType" />
                        <TextInput source="display" />
                        <TextInput source="title" />
                        <TextInput source="description" />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="serviceability"
                            choices={serviceability}
                        />
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <ArrayInput source="steps">
                        <SimpleFormIterator>
                            {/* <TextInput source="title" /> */}
                            <TextInput source="description" />
                            <ImageInput
                                source="imagePath"
                                label="Image"
                                accept="image/*"
                                placeholder={
                                    <p>
                                        {translation(
                                            'resources.ServiceMode.fields.input_file'
                                        )}
                                    </p>
                                }
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default ServiceModeCreate;
