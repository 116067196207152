import { useForm, useFormState } from 'react-final-form';
import { useEffect, useMemo } from 'react';

const FIELDS = {
    EASY_ENROLLMENT: 'easyEnrollment',
    STANDARD_ENROLLMENT: 'standardEnrollment',
    SINGLE_PAIRING: 'singlePairing',
    RESET_GESTURE: 'resetGesture',
};
export const EnrollmentSection = ({ children }) => {
    const formState = useFormState();
    const form = useForm();

    const enrollmentType = useMemo(() => formState.values.options, [formState]);
    useEffect(() => {
        const {
            active,
            values: { singlePairing },
        } = formState;
        const activeValue = formState.values[active];

        if (
            [FIELDS.EASY_ENROLLMENT, FIELDS.STANDARD_ENROLLMENT].includes(
                active
            ) &&
            !singlePairing
        ) {
            form.change(
                active === FIELDS.EASY_ENROLLMENT
                    ? FIELDS.STANDARD_ENROLLMENT
                    : FIELDS.EASY_ENROLLMENT,
                !activeValue
            );
        } else if (active === FIELDS.SINGLE_PAIRING && activeValue) {
            form.change(FIELDS.STANDARD_ENROLLMENT, true);
            form.change(FIELDS.EASY_ENROLLMENT, true);
        }
    }, [formState, form]);

    useEffect(() => {
        if (enrollmentType !== null) {
            if (enrollmentType) {
                form.batch(() => {
                    form.change(FIELDS.EASY_ENROLLMENT, false);
                    form.change(FIELDS.STANDARD_ENROLLMENT, false);
                    form.change(FIELDS.SINGLE_PAIRING, false);
                });
            } else {
                form.change(FIELDS.RESET_GESTURE, false);
            }
        }
    }, [enrollmentType, form]);
    return children;
};

export default EnrollmentSection;
