import { useEffect, useCallback, useState } from 'react';

const useEnrollmentForm = (isEdit = false) => {
    const [enrollmentType, setEnrollmentType] = useState(null);

    const [resetGestureDisabled, setResetGestureDisabled] = useState(false);
    const [easyEnrollmentDisabled, setEasyEnrollmentDisabled] = useState(false);
    const [standardEnrollmentDisabled, setStandardEnrollmentDisabled] =
        useState(false);
    const [singlePairingDisabled, setSinglePairingDisabled] = useState(false);

    useEffect(() => {
        if (enrollmentType !== null || !isEdit) {
            const isDisabled = !!enrollmentType;

            setResetGestureDisabled(!isDisabled);

            setEasyEnrollmentDisabled(isDisabled);
            setStandardEnrollmentDisabled(isDisabled);
            setSinglePairingDisabled(isDisabled);
        }
    }, [enrollmentType]);

    const handleEnrollmentTypeChange = useCallback((event) => {
        setEnrollmentType(event.target.value);
    }, []);

    const onInputChange = useCallback((field, value) => {
        if (field === 'singlePairing') {
            setEasyEnrollmentDisabled(value);
            setStandardEnrollmentDisabled(value);
        }
    }, []);

    return {
        enrollmentType,
        resetGestureDisabled,
        easyEnrollmentDisabled,
        standardEnrollmentDisabled,
        singlePairingDisabled,
        handleEnrollmentTypeChange,
        onInputChange,
        setEnrollmentType,
    };
};

export default useEnrollmentForm;
