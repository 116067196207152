import SettingsIcon from '@material-ui/icons/Settings';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    ArrayInput,
    Edit,
    ImageField,
    required,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextInput,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import serviceability from '../../data/serviceability.json';
import loadingTypes from '../../data/loadingType.json';
import installationTypes from '../../data/installationType.json';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
import options from './serviceMode.mock';
const ServiceModeEdit = ({ permissions = {}, ...props }) => {
    const translation = useTranslate();
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands).filter((x) => x.id !== 'all');
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    const isEditDisabled = !permissions?.['service-mode']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.service-mode.name'),
            url: 'service-mode',
            icon: <SettingsIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceTypes}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="brand"
                            choices={[
                                ...brands,
                                {
                                    id: 'TERZISMO',
                                    name: 'TERZISMO',
                                },
                            ]}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="series"
                            choices={series.sort((a, b) =>
                                a.name.localeCompare(b.name)
                            )}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="seriesVersion"
                            disabled={isEditDisabled}
                            choices={seriesVersion}
                        />
                        <CustomSelectInput
                            source="loadingType"
                            allowEmpty
                            choices={loadingTypes}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="installationType"
                            disabled={isEditDisabled}
                            choices={installationTypes}
                        />
                        <TextInput source="display" disabled={isEditDisabled} />
                        <TextInput
                            source="title"
                            label="title"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="description"
                            label="description"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="serviceability"
                            choices={serviceability}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ArrayInput source="steps" disabled={isEditDisabled}>
                        <SimpleFormIterator
                            getItemLabel={(index) => `${index + 1}-key`}
                        >
                            <SimpleShowLayout>
                                <ImageField source="imagePath" />
                            </SimpleShowLayout>
                            {/* <TextInput label="title" source="title" /> */}
                            <TextInput
                                label="description"
                                source="description"
                            />
                            <ImageInput
                                source="imagePath"
                                label="Image"
                                accept="image/*"
                                placeholder={<p>Drop your file here</p>}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default ServiceModeEdit;
