import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';

import React, { useState, useCallback, useMemo } from 'react';
import {
    ArrayInput,
    BooleanInput,
    Create,
    ImageField,
    NumberInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import display from '../../data/display.json';
import loadingType from '../../data/loadingType.json';
import type_ from '../../data/type.json';
import options from './applianceWakeUp.mock';
import EnrollmentSection from '../../components/enrollmentSection';
import useEnrollmentForm from '../../hooks/useEnrollmentForm';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
import dryingTechnology from '../../data/dryingTechnology.json';
import installationType from '../../data/installationType.json';

const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});

const ApplianceWakeUpCreate = (props) => {
    const [tdChoices, setTdChoices] = useState(false);
    const classes = useStyles();
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);

    const {
        resetGestureDisabled,
        easyEnrollmentDisabled,
        standardEnrollmentDisabled,
        singlePairingDisabled,
        handleEnrollmentTypeChange,
        onInputChange,
    } = useEnrollmentForm();

    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.applianceWakeUp.name'),
            url: 'appliance-wake.up',
            icon: <ErrorIcon />,
        },
        {
            text: translation('resources.applianceWakeUp.create.name'),
            url: '',
        },
    ];

    const enrollmentSection = useMemo(() => {
        return (
            <EnrollmentSection isEdit={false}>
                <BooleanInput
                    // input={<Switch></Switch>}
                    initialValue={false}
                    disabled={resetGestureDisabled}
                    source={'resetGesture'}
                    label="Reset gesture"
                />
                <BooleanInput
                    initialValue={false}
                    disabled={easyEnrollmentDisabled}
                    source="easyEnrollment"
                    label="Easy enrollment"
                    onChange={(val) => onInputChange('easyEnrollment', val)}
                />

                <BooleanInput
                    initialValue={false}
                    disabled={standardEnrollmentDisabled}
                    source="standardEnrollment"
                    label="Standard enrollment"
                    onChange={(val) => onInputChange('standardEnrollment', val)}
                />
                <BooleanInput
                    initialValue={false}
                    disabled={singlePairingDisabled}
                    source="singlePairing"
                    label="Single pairing"
                    onChange={(val) => onInputChange('singlePairing', val)}
                />
            </EnrollmentSection>
        );
    });

    const handleApplianceTypeChange = useCallback((event) => {
        setTdChoices(event.target.value === 'TD');
    }, []);
    return (
        <>
            <form>
                <CustomBreadcrumbs data={breadcrumbsData} />
                <Create {...props}>
                    <SimpleForm>
                        <ContainerFieldsList>
                            <CustomSelectInput
                                source="applianceType"
                                choices={applianceTypes}
                                validate={required()}
                                onChange={handleApplianceTypeChange}
                            />
                            <CustomSelectInput
                                allowEmpty
                                source="brand"
                                choices={brands}
                            />
                            <CustomSelectInput
                                allowEmpty
                                source="series"
                                choices={series}
                            />
                            <CustomSelectInput
                                allowEmpty
                                choices={seriesVersion}
                                source="seriesVersion"
                            />
                            <CustomSelectInput
                                allowEmpty
                                source="loadingType"
                                choices={loadingType}
                            />
                            <CustomSelectInput
                                source="installationType"
                                choices={installationType}
                            />
                            <CustomSelectInput
                                label="Drying Technology"
                                source="dryingTechnology"
                                choices={dryingTechnology}
                            />
                            <CustomSelectInput
                                allowEmpty
                                source="type"
                                choices={type_}
                            />
                            <CustomSelectArrayInput
                                allowEmpty
                                source="display"
                                choices={display}
                            />
                            <TextInput source="title" validate={required()} />
                            <TextInput source="description" />

                            <CustomSelectInput
                                allowEmpty
                                source="options"
                                label="enrollment Type"
                                choices={options}
                                onChange={handleEnrollmentTypeChange}
                                translateChoice={false}
                            />
                        </ContainerFieldsList>
                        <ContainerFieldsList>
                            {enrollmentSection}
                        </ContainerFieldsList>
                        <hr className={classes.width} />
                        <ArrayInput source="steps">
                            <SimpleFormIterator>
                                {/* <TextInput source="title" /> */}
                                <TextInput
                                    source="stepTitle"
                                    label="Step title"
                                    validate={required()}
                                />
                                <TextInput
                                    source="stepSubtitle"
                                    label="Step subtitle"
                                />
                                <TextInput source="description" />
                                <BooleanInput
                                    initialValue={false}
                                    source="standard"
                                    label="Standard flow (wifi)"
                                />
                                <NumberInput source="delay" label="Delay" />
                                <ImageInput
                                    source="imagePath"
                                    label="Image"
                                    accept="image/*"
                                    placeholder={
                                        <p>
                                            {translation(
                                                'resources.applianceWakeUp.fields.input_file'
                                            )}
                                        </p>
                                    }
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleForm>
                </Create>
            </form>
        </>
    );
};
export default ApplianceWakeUpCreate;
